
import React from 'react'
import { Link } from './localizedLink'
import Img from 'gatsby-image'

const { slugify } = require('../utility/utils');

class Prodotti extends React.Component{

  render(){
    const {prodotti, variation} = this.props;
    
    return (
        <div className="row">
            {prodotti.map((item,index) => {
                return (
                <div key={index} className="col-lg-4 col-md-6">
                    {variation === 'nolink'  ? (
                        <div className="card">
                            <Img className="card__copertina" fluid={item.copertina.fluid} />
                            <div className="card__title text-center">{item.titolo}</div>
                        </div>    
                           
                    ) : (
                        <div className="card">
                            <Link to={`/cosa-faccio/prodotti/${slugify(item.titolo)}/`} className="card__copertinawrapper imghover">
                                <Img className="card__copertina" fluid={item.copertina.fluid} />
                            </Link>
                            <div className="card__title text-center">
                                <Link to={`/cosa-faccio/prodotti/${slugify(item.titolo)}/`}>{item.titolo}</Link>
                            </div>
                        </div>     
                    )}
                    
                </div> 
                )
                
            })}
        </div>
    );
  }

}

export default Prodotti
