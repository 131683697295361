import React from 'react'
import { injectIntl } from 'react-intl'
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

const Contattami = (data) => {

  const { avatar, email, fb, insta } = useStaticQuery(
    graphql`
      query {
        avatar: file(name: {eq: "giulia"}) {
          name
          childImageSharp {
            fluid(maxWidth:180) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        email: file(name: {eq: "icona_email"}) {
          name
          childImageSharp {
            fixed(width:60) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        fb: file(name: {eq: "icona_fb"}) {
          name
          childImageSharp {
            fixed(width:60) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        insta: file(name: {eq: "icona_insta"}) {
          name
          childImageSharp {
            fixed(width:60) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const testo = data.action || 'contattami';
  const { intl: { formatMessage } } = data;

  return (
    <div className="section contattami">
      <div className="contattami__wrapper">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-3 offset-md-1 text-center text-md-left">
            <Img className="contattami__ritratto" fluid={avatar.childImageSharp.fluid} alt="Giulia Clerici"/>
          </div>
          <div className="col-sm-12 col-md-7">
            <div className="contattami__title">
              {formatMessage({ "id": testo })}
            </div>
            <div className="contattami__social">
              {/* eslint-disable-next-line */}
              <a title="Scrivimi" href="mailto:info@giuliaclerici.com" rel="noopener noreferrer" target="_blank">
                <Img  alt="email" fixed={email.childImageSharp.fixed} />
              </a>
              {/* eslint-disable-next-line */}
              <a title="Facebook ricettedisegnate" href="https://www.facebook.com/ricettedisegnate/" rel="noopener noreferrer" target="_blank">
                <Img  alt="facebook" fixed={fb.childImageSharp.fixed} />
              </a>
              {/* eslint-disable-next-line */}
              <a title="Instagram ricettedisegnate" href="https://www.instagram.com/ricettedisegnate/" rel="noopener noreferrer" target="_blank">
                <Img  alt="instagram" fixed={insta.childImageSharp.fixed} />
              </a>
              {/* <a title="Pinterest ricettedisegnate" href="https://www.pinterest.it/ricettedisegnate/" rel="noopener noreferrer" target="_blank">
                    <img alt="Pinterest" src="/images/pinterest.png"/>
                </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(Contattami)